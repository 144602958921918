.Intro {
  display: flex;
  &__new {
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 100vh;
    padding: 1rem;
    flex-direction: column;
    text-align: start;

    &__img {
      top: 0;
      left: 0;
      position: absolute;
      padding: 20px;
      img {
        width: 130px;
        object-fit: contain;
      }
    }

    h2 {
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 24px;
    }

    p {
      max-width: 550px;
      margin-top: 30px;
      color: #65676b;
      font-size: 12px;
    }

    &__body {
      padding-top: 1rem;
      border-top: 1px solid #e5e7eb;
      margin-top: 1rem;
      &-red {
        color: #dc2726 !important;
      }
    }

    &-related-topics {
      margin: 30px 0;
      h1 {
        font-size: 1.25rem !important;
        color: #050505;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: 20px;
      }
      &-cards {
        display: flex;
        @media only screen and (max-width: 700px) {
          flex-direction: column;
        }
      }
      &-card {
        display: flex;
        flex-direction: column;
        max-width: 36rem;
        padding: 1rem;
        background-color: #fff;
        border-radius: 8px;
        text-align: start;
        h1 {
          font-size: 1.25rem !important;
          color: #1f2a37;
          font-weight: 500;
          line-height: 1.5rem;
        }
        p {
          margin-bottom: 0;
          word-wrap: break-word;
          font-size: 0.875rem;
          line-height: 1.25rem;
          overflow-x: hidden;
          word-break: break-word;
          font-weight: 400;
          color: #1f2a37;
        }
      }
    }
    button {
      display: flex;
      margin-top: 2rem;
      outline: none;
      border: none;
      background-color: #224ed8;
      line-height: 1.5;
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #fff;
      align-self: flex-start !important;
      cursor: pointer;
      border: 1px solid #224ed8;
      span {
        margin: 0 !important;
        padding: 0;
        text-transform: none;
      }
      &:hover {
        background-color: #224ed8;
      }
    }
  }
}
